import { getMarket } from "@projectluna/lib/market/server";
import { type MatchedMarket } from "@projectluna/lib/market/types";
import { getTranslation } from "@projectluna/lib/saleor/intl";

import { CLIENT_CONFIG } from "@/config/client";
import { SERVER_CONFIG } from "@/config/server";
import {
  ProductCardsQueryDocument,
  type ProductsPathQuery,
  ProductsPathQueryDocument,
  type ProductsPathQueryVariables,
  ProductsSearchDocument,
} from "@/graphql/queries/generated";
import { mapEdgesToItems } from "@/lib/gql";
import { getGqlClient } from "@/lib/graphql/client";
import { paginatedQuery } from "@/lib/ssr/utils";
import { getMostViewedProductsSlug } from "@/lib/tracking/google/server";
import type { Maybe } from "@/lib/types";

export const fetchAllProductsSlug = async (channel: string) => {
  const client = getGqlClient();

  const fetchQuery = await paginatedQuery<
    ProductsPathQuery,
    ProductsPathQueryVariables
  >(client, ProductsPathQueryDocument);

  const fetchAll = async (
    after: Maybe<string>,
    items: { slug: string }[]
  ): Promise<{ slug: string }[]> => {
    const { products } = await fetchQuery({ after, channel });

    items.push(...(products?.edges?.map(edge => edge?.node) || []));

    if (items.length > SERVER_CONFIG.SSR_FETCH_LIMIT) {
      return items;
    }

    if (products?.pageInfo?.hasNextPage) {
      return fetchAll(products?.pageInfo?.endCursor, items);
    }

    return items;
  };

  return fetchAll(null, []);
};

export type SearchProduct = {
  name: string;
  slug: string;
};

export const searchProducts = async ({
  market,
  search,
}: {
  market: MatchedMarket;
  search: string;
}): Promise<SearchProduct[]> => {
  const client = getGqlClient();

  const data = await client.execute(ProductsSearchDocument, {
    variables: {
      languageCode: market.locale.code,
      channel: market.channelSlug,
      limit: CLIENT_CONFIG.PAGINATION_SIZE.SEARCH,
      search,
    },
  });

  return (
    data?.products?.edges.map(({ node }) => ({
      slug: node.slug,
      name: getTranslation("name", node),
    })) ?? []
  );
};

export const fetchPopularProductsCard = async (
  limit: number = CLIENT_CONFIG.PAGINATION_SIZE.POPULAR_PRODUCTS
) => {
  const [slugs, market] = await Promise.all([
    getMostViewedProductsSlug(),
    getMarket(),
  ]);

  const client = getGqlClient();

  const newestProductsData = await client.execute(ProductCardsQueryDocument, {
    variables: {
      countryCode: market.code,
      channel: market.slug,
      languageCode: market.locale.code,
      thumbnailFormat: CLIENT_CONFIG.IMAGES_FORMAT,
      thumbnailSize: CLIENT_CONFIG.IMAGES_SIZE.PRODUCT_CARD,
      first: limit,
      filter: {
        slugs,
      },
    },
    options: {
      next: {
        revalidate: SERVER_CONFIG.CACHE_TTL_POPULAR_PRODUCTS,
        tags: ["POPULAR-PRODUCTS"],
      },
    },
  });

  return mapEdgesToItems(newestProductsData?.products);
};
