import { type DocumentTypeDecoration } from "@graphql-typed-document-node/core";

import { type GraphqlClient } from "@projectluna/lib/graphql/client";
import { type AnyVariables } from "@projectluna/lib/graphql/types";

import { SERVER_CONFIG } from "@/config/server";

export const paginatedQuery =
  async <T, V extends AnyVariables>(
    client: GraphqlClient,
    query: DocumentTypeDecoration<T, V>
  ) =>
  async (variables: V) => {
    const data = await client.execute<T, V>(query, {
      variables: {
        first: SERVER_CONFIG.SSR_FETCH_BATCH,
        ...variables,
      },
    });

    return (data || {}) as T;
  };
