import(/* webpackMode: "eager", webpackExports: ["ReactComponent"] */ "/vercel/path0/apps/storefront/src/assets/svg/facebook.svg");
;
import(/* webpackMode: "eager", webpackExports: ["ReactComponent"] */ "/vercel/path0/apps/storefront/src/assets/svg/instagram.svg");
;
import(/* webpackMode: "eager", webpackExports: ["ReactComponent"] */ "/vercel/path0/apps/storefront/src/assets/svg/logo_sign.svg");
;
import(/* webpackMode: "eager", webpackExports: ["ReactComponent"] */ "/vercel/path0/apps/storefront/src/assets/svg/logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["ReactComponent"] */ "/vercel/path0/apps/storefront/src/assets/svg/pinterest.svg");
;
import(/* webpackMode: "eager", webpackExports: ["ReactComponent"] */ "/vercel/path0/apps/storefront/src/assets/svg/tiktok.svg");
;
import(/* webpackMode: "eager", webpackExports: ["MobileMenu"] */ "/vercel/path0/apps/storefront/src/components/Header/components/MobileMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PromotionsBar"] */ "/vercel/path0/apps/storefront/src/components/Header/components/PromotionsBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Search"] */ "/vercel/path0/apps/storefront/src/components/Header/components/Search.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShoppingBagIcon"] */ "/vercel/path0/apps/storefront/src/components/Header/components/ShoppingBagIcon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Wrapper"] */ "/vercel/path0/apps/storefront/src/components/Header/components/Wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Cart"] */ "/vercel/path0/apps/storefront/src/components/SideCart/components/Cart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartDrawer"] */ "/vercel/path0/apps/storefront/src/components/SideCart/components/CartDrawer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EmptyCart"] */ "/vercel/path0/apps/storefront/src/components/SideCart/components/EmptyCart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.19.1_next@14.3.0-canary.87_@babel+core@7.25.2_@opentelemetry+api@1.9.0_react-dom@_e3xbgpjjn56harlavteur7prom/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next@14.2.1-canary.7_@babel+core@7.25.2_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
