import { Children, cloneElement, isValidElement } from "react";

import {
  type CardProduct,
  type ProductCardCommonProps,
} from "@/components/ProductCard/types";
import { type WithChildren, type WithHTMLProp } from "@/lib/types";

import { Badge } from "./components/Badge";
import { Image } from "./components/Image";
import { Name } from "./components/Name";
import { Price } from "./components/Price";

import { cn } from "@/styles/lib";

type ProductCardInjectedProps = {
  product: CardProduct;
} & ProductCardCommonProps;

export type ProductCardProps = ProductCardInjectedProps &
  WithChildren &
  WithHTMLProp<"className">;

const ProductCard = ({
  children,
  variant = "default",
  product: { isAvailable },
  product,
  analyticsName,
  className,
}: ProductCardProps) => {
  const injectedProps: ProductCardInjectedProps = {
    variant,
    product,
    analyticsName,
  };

  return (
    <div
      className={cn(
        "card-gap relative grid w-full transition-opacity duration-1000",
        {
          "[&_img:not(mark)]:opacity-40": !isAvailable,
          "!md:gap-1 !gap-0.5": variant === "sm",
        },
        className
      )}
    >
      {Children.map(children, child => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            ...child.props,
            ...injectedProps,
          });
        }
        return child;
      })}
    </div>
  );
};

ProductCard.Price = Price;
ProductCard.Name = Name;
ProductCard.Image = Image;
ProductCard.Badge = Badge;

export { ProductCard };
