"use client";

import { Dialog, Transition } from "@headlessui/react";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useClickAway } from "@uidotdev/usehooks";
import debounce from "lodash/debounce";
import { useTranslations } from "next-intl";
import { useRouter } from "next-nprogress-bar";
import {
  Fragment,
  type KeyboardEventHandler,
  startTransition,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSwipeable } from "react-swipeable";

import { useGetMarket } from "@projectluna/lib/market/client";
import { useLocalizedPaths } from "@projectluna/lib/paths/client";
import { QUERY_PARAMS } from "@projectluna/lib/paths/const";

import { Button } from "@/components/Button";
import { Logo } from "@/components/Header/components/Logo";
import { Icon } from "@/components/Icon";
import { Input } from "@/components/Input";
import { Link } from "@/components/Link";
import { ProductCard } from "@/components/ProductCard";
import { Spinner } from "@/components/Spinner";
import { TransitionBackdrop } from "@/components/TransitionBackdrop";
import { CLIENT_CONFIG } from "@/config/client";
import { type ProductCardFragment } from "@/graphql/fragments/generated";
import { useOnRouteChange } from "@/hooks/useOnRouteChange";
import { useWindowEvent } from "@/hooks/useWindowEvent";
import { type SearchProduct, searchProducts } from "@/lib/store/product";
import { trackActivity } from "@/lib/tracking/handlers";

import { cn } from "@/styles/lib";

type SearchState = {
  hasItems: boolean | null;
  isProcessing: boolean;
  items: SearchProduct[];
  searchTerm: string;
};

const ESCAPE_KEYS = ["27", "Escape"];
const INITIAL_STATE: SearchState = {
  searchTerm: "",
  items: [],
  hasItems: null,
  isProcessing: false,
};

export const Search = ({
  popularProducts,
}: {
  popularProducts: ProductCardFragment[];
}) => {
  const market = useGetMarket();
  const t = useTranslations();
  const router = useRouter();
  const paths = useLocalizedPaths();

  const [isActive, setIsActive] = useState(false);
  const [state, setState] = useState<SearchState>(INITIAL_STATE);
  const inputRef = useRef<HTMLInputElement>(null);

  const searchPath = paths.search.asPath({
    query: { [QUERY_PARAMS.search]: state.searchTerm },
  });

  const updateState = (state: Partial<SearchState>) =>
    setState(prev => ({ ...prev, ...state }));

  const handleSearch = useCallback(
    debounce(
      async (searchTerm: string) => {
        if (searchTerm.length < CLIENT_CONFIG.SEARCH_MIN_CHARS || !isActive) {
          return;
        }

        updateState({
          items: [],
          isProcessing: true,
          hasItems: null,
        });

        trackActivity.search({ searchTerm });
        const products = await searchProducts({ market, search: searchTerm });

        if (isActive) {
          updateState({
            items: products,
            isProcessing: false,
            hasItems: !!products.length,
          });
        }
      },
      CLIENT_CONFIG.DEBOUNCE_MS,
      { leading: false, trailing: true }
    ),
    [isActive]
  );

  const handleReset = useCallback(() => {
    setState(INITIAL_STATE);
    handleSearch.cancel();
  }, [handleSearch]);

  const handleClose = useCallback(() => {
    handleReset();
    setIsActive(false);
  }, [isActive, state]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = evt => {
    if (
      evt.code === "Enter" &&
      !state.isProcessing &&
      state.searchTerm.length >= CLIENT_CONFIG.SEARCH_MIN_CHARS
    ) {
      evt.preventDefault();
      handleClose();
      startTransition(() => router.push(searchPath));
    }
  };

  const clickAwayRef = useClickAway<HTMLDivElement>(handleClose);
  const handlers = useSwipeable({
    onSwiped: ({ dir, event }) => {
      const shouldIgnore = (event.target as HTMLElement)?.closest(
        "[data-ignore-swipe]"
      );
      const isTopSwipe = dir === "Up";

      const isPrevented = event.defaultPrevented;

      if (!shouldIgnore && !isPrevented && isTopSwipe) {
        handleClose();
      }
    },
    trackMouse: true,
    delta: 20,
  });

  useOnRouteChange(handleClose);

  useWindowEvent("keydown", evt => {
    if (ESCAPE_KEYS.includes(String(evt.key)) && isActive) {
      handleClose();
      setIsActive(false);
    }
  });

  useEffect(() => {
    setState(INITIAL_STATE);
    setTimeout(() => inputRef.current?.focus());
  }, [isActive]);

  return (
    <>
      <Icon onClick={() => setIsActive(true)}>
        <MagnifyingGlassIcon />
      </Icon>

      <Transition appear as={Fragment} show={isActive}>
        <Dialog
          as="div"
          className="fixed left-0 right-0 top-0 z-50 h-full"
          onClose={() => null} // This is add only to prevent document scrolling when open.
        >
          <TransitionBackdrop enter="delay-[50ms]" leave="duration-[50ms]" />

          <Transition.Child
            enter="transition-all duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div {...handlers}>
              <div
                className="relative w-full bg-white shadow-xl max-lg:h-dvh lg:min-h-72"
                ref={clickAwayRef}
              >
                <div className="container flex h-[64px] items-center gap-32">
                  <Logo className="max-lg:hidden" />

                  <div className="w-full">
                    <Transition.Child
                      as={Fragment}
                      enter="transition-all duration-200 delay-[15ms]"
                      enterFrom="-mt-10 opacity-0"
                      enterTo="mt-0 opacity-100"
                      leave="transition-all duration-150"
                      leaveFrom="mt-0 opacity-100"
                      leaveTo="-mt-4 opacity-0"
                    >
                      <div className="flex lg:gap-32">
                        <Input
                          autoFocus
                          className={cn("mb-0", {
                            "pointer-events-none": state.isProcessing,
                          })}
                          endIcon={<XMarkIcon onClick={handleReset} />}
                          inputClassName={cn("rounded-full", {
                            // Disabled state hides mobile keyboard which makes a very bad UX when
                            // the keyboard jumps when searching for sth. this imitate the disabled state.
                            "opacity-50 focus-within:ring-0 focus:outline-gray-300":
                              state.isProcessing,
                          })}
                          name="search"
                          placeholder={t("common.search")}
                          ref={inputRef}
                          startIcon={<MagnifyingGlassIcon />}
                          value={state.searchTerm}
                          onChange={evt => {
                            if (state.isProcessing) {
                              return;
                            }

                            const searchTerm = evt.target.value.trimStart();
                            updateState({
                              searchTerm,
                              hasItems: null,
                            });
                            handleSearch(searchTerm);
                          }}
                          onKeyDown={handleKeyDown}
                        />
                        <Button
                          className="max-sm:pr-0"
                          variant="text"
                          onClick={handleClose}
                        >
                          {t("common.close")}
                        </Button>
                      </div>
                    </Transition.Child>
                  </div>
                </div>

                <div className="border-t border-t-gold-100">
                  <div className="container grid gap-8 py-10">
                    {state.isProcessing && (
                      <Spinner className="mx-auto my-10" size={35} />
                    )}

                    {state.hasItems && (
                      <>
                        <ul className="leading-9 lg:px-[17rem]">
                          {state.items.map(({ name, slug }) => (
                            <li
                              className="group flex flex-row items-center gap-2 transition-opacity hover:opacity-70"
                              key={slug}
                            >
                              <MagnifyingGlassIcon
                                className="transition-colors group-hover:text-gold"
                                height={17}
                              />
                              <Link href={paths.product.asPath({ slug })}>
                                {name}
                              </Link>
                            </li>
                          ))}
                          <li>
                            <Link
                              className="flex flex-row items-center gap-2 py-2 text-lg text-gold"
                              href={searchPath}
                            >
                              <ArrowUturnLeftIcon
                                className="rotate-180 transition-colors group-hover:text-gold"
                                height={24}
                              />
                              {t("common.search")} `{state.searchTerm}`
                            </Link>
                          </li>
                        </ul>
                      </>
                    )}

                    {state.hasItems === false && (
                      <p className="text-center">
                        {t("common.sorryNoResults")}
                      </p>
                    )}

                    {!state.hasItems && !state.isProcessing && (
                      <>
                        <p className="text-center font-semibold text-gray-500">
                          {t("common.popularProducts")}
                        </p>

                        <div className="grid grid-cols-3 gap-4 lg:mx-auto lg:grid-cols-6 lg:gap-8">
                          {popularProducts.map(product => (
                            <ProductCard
                              analyticsName="Newest products carousel"
                              key={product.id}
                              product={product}
                              variant="sm"
                            >
                              <ProductCard.Badge />
                              <ProductCard.Image />
                              <ProductCard.Name />
                              <ProductCard.Price />
                            </ProductCard>
                          ))}
                        </div>

                        <Link
                          className="mx-auto flex flex-row items-center gap-2 text-center text-sm font-semibold text-gray-500"
                          href={paths.search.asPath()}
                        >
                          <ArrowUturnLeftIcon
                            className="rotate-180 transition-colors group-hover:text-gold"
                            height={24}
                          />

                          {t("common.seeAll")}
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};
