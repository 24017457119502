"use client";

import { useTranslations } from "next-intl";
import { useEffect, useState, useTransition } from "react";

import { useLocalizedPaths } from "@projectluna/lib/paths/client";

import { Button } from "@/components/Button";
import type { QuantityAction } from "@/components/CartQuantityInput";
import { Link } from "@/components/Link";
import { ShoppingBag } from "@/components/ShoppingBag";
import { type CheckoutBaseFragment } from "@/graphql/fragments/generated";
import { useServerErrors } from "@/hooks/useServerErrors";
import {
  checkoutLinesDeleteAction,
  checkoutLinesUpdateAction,
} from "@/lib/actions/checkout";
import { extractModifiedLine } from "@/lib/checkout/helpers";
import { trackActivity } from "@/lib/tracking/handlers";
import { useSideCartContext } from "@/providers/SideCartProvider";

type CartProps = {
  checkout: CheckoutBaseFragment;
};

export const Cart = ({
  checkout: { lines, totalPrice, id },
  checkout,
}: CartProps) => {
  const t = useTranslations();
  const paths = useLocalizedPaths();
  const { clearApiErrors, apiErrorsHandler } = useServerErrors({
    errorType: "checkout",
    snackVariant: "warning",
    snackPersist: false,
  });
  const [isTransitioning, startTransition] = useTransition();
  const [processingLine, setProcessingLine] = useState<string | null>(null);
  const { setIsOpen } = useSideCartContext();

  const handleLineDelete = async (lineId: string) => {
    await clearApiErrors();
    setProcessingLine(lineId);

    startTransition(() => {
      (async () => {
        const errors = await checkoutLinesDeleteAction({
          id,
          linesIds: [lineId],
        });

        if (errors.length) {
          apiErrorsHandler(errors);
        } else {
          const { linesRemoved } = extractModifiedLine({
            lines,
            linesInput: [{ lineId }],
          });

          if (linesRemoved.length) {
            trackActivity.removeFromCart(linesRemoved);
          }
        }
      })();
    });
  };

  const handleLineQuantityChange = async (
    lineId: string,
    quantity: number,
    action: QuantityAction
  ) => {
    await clearApiErrors();
    setProcessingLine(lineId);

    startTransition(() => {
      (async () => {
        const errors = await checkoutLinesUpdateAction({
          id,
          lines: [{ lineId, quantity }],
        });

        if (errors.length) {
          apiErrorsHandler(errors);
        } else {
          const { linesAdded, linesRemoved } = extractModifiedLine({
            lines,
            linesInput: [{ lineId, quantity, action }],
          });

          if (linesRemoved.length) {
            trackActivity.removeFromCart(linesRemoved);
          } else if (linesAdded.length) {
            trackActivity.addToCart(linesAdded);
          }
        }
      })();
    });
  };

  useEffect(() => {
    if (!isTransitioning && processingLine) {
      setProcessingLine(null);
    }
  }, [isTransitioning]);

  useEffect(() => {
    trackActivity.viewCart(checkout);

    /**
     * Close upon empty cart. When this component is unmounted the cart is empty or is being closed.
     */
    return () => {
      setIsOpen(false);
    };
  }, []);

  return (
    <>
      <ShoppingBag.Lines
        isProcessing={isTransitioning}
        lines={lines}
        processingLine={processingLine}
        onLineDelete={handleLineDelete}
        onLineQuantityChange={handleLineQuantityChange}
      />

      <ShoppingBag.TotalPrice price={totalPrice.gross} />

      <Link
        href={paths.cart.asPath()}
        isDisabled={isTransitioning}
        onClick={() => setIsOpen(false)}
      >
        <Button isFullWidth disabled={isTransitioning} variant="outlined">
          {t("checkout.showCart")}
        </Button>
      </Link>

      <Link
        href={paths.checkout.asPath()}
        isDisabled={isTransitioning}
        onClick={() => setIsOpen(false)}
      >
        <Button isFullWidth color="gold" disabled={isTransitioning}>
          {t("checkout.checkOut")}
        </Button>
      </Link>
    </>
  );
};
