import { type Maybe } from "@/lib/types";

interface Edge<T> {
  node: T;
}
interface Connection<T> {
  edges: Maybe<Array<Edge<T>>>;
}

export function mapEdgesToItems<T>(
  data: Maybe<Connection<T>>,
  defaultValue = []
): T[] {
  return data?.edges?.map(({ node }) => node) ?? defaultValue;
}
