import { type Paths } from "@projectluna/lib/paths/types";

import { type NavItem } from "@/components/Header/types";
import { type MenuItemFragment } from "@/graphql/fragments/generated";
import { getLinkName, getNavigationLinkProps } from "@/lib/menu";
import { type Maybe } from "@/lib/types";

export const menuToNavItems = ({
  paths,
  menu,
  host,
}: {
  host: string;
  menu: Maybe<{ items?: Maybe<MenuItemFragment[]> }>;
  paths: Paths;
}): NavItem[] => {
  const recursiveItems = (
    children: Array<
      {
        children?: Maybe<MenuItemFragment[]>;
      } & MenuItemFragment
    >
  ): NavItem[] =>
    children.map(child => {
      if (!child.children?.length) {
        return {
          text: getLinkName(child),
          linkProps: getNavigationLinkProps({ item: child, paths, host }),
        };
      }

      return {
        items: recursiveItems(child.children),
        text: getLinkName(child),
        linkProps: getNavigationLinkProps({ item: child, paths, host }),
      };
    }) as NavItem[];

  return recursiveItems(menu?.items ?? []);
};
