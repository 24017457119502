
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"25055e4469d1c10268d1f06f263a5975df6e3b3d":"getCachedMostViewedProductsSlug","4feca3c536e47a3b935f17ca36c64510526bb241":"$$ACTION_0","7ddd6bb252d55697f38fe3a61c60cef30e37be8e":"getMostViewedProductsSlug"} */ export var getCachedMostViewedProductsSlug = createServerReference("25055e4469d1c10268d1f06f263a5975df6e3b3d");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var getMostViewedProductsSlug = createServerReference("7ddd6bb252d55697f38fe3a61c60cef30e37be8e");

